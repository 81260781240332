import { match, P } from 'ts-pattern';
import {
  ContactInteractionPhoneCall,
  ContactInteractionEmail,
  ContactInteractionFacebookMessenger,
  ContactInteractionSms,
  ContactInteractionWhatsapp,
  ContactInteractionInPerson,
  ContactInteractionFacebookAd,
  ContactInteractionUnion,
  ContactInteractionInstagram,
  ContactInteractionParticipatingEntityEdge,
} from '../types/__generated__/graphql';

type ContactInteractionPhoneCallHandlers<Result> = {
  defaultInbound: (contactInteraction: ContactInteractionPhoneCall) => Result;
  defaultOutbound: (contactInteraction: ContactInteractionPhoneCall) => Result;
  inboundManualWithParticipatingEntity: (
    contactInteraction: ContactInteractionPhoneCall & {
      participatingEntity: ContactInteractionParticipatingEntityEdge;
    },
  ) => Result;
  inboundManualWithoutParticipatingEntity: (
    contactInteraction: ContactInteractionPhoneCall & { participatingEntity?: null },
  ) => Result;
  inboundAnsweredWithParticipatingEntity: (
    contactInteraction: ContactInteractionPhoneCall & {
      participatingEntity: ContactInteractionParticipatingEntityEdge;
    },
  ) => Result;
  inboundAnsweredWithoutParticipatingEntity: (
    contactInteraction: ContactInteractionPhoneCall & { participatingEntity?: null },
  ) => Result;
  inboundUnansweredWithDialStepMenu: (
    contactInteraction: ContactInteractionPhoneCall,
  ) => Result;
  inboundUnansweredWithDialStepMessage: (
    contactInteraction: ContactInteractionPhoneCall,
  ) => Result;
  inboundUnansweredWithDialStepDial: (
    contactInteraction: ContactInteractionPhoneCall,
  ) => Result;
  inboundUnansweredWithDialStepVoicemailWithRecording: (
    contactInteraction: ContactInteractionPhoneCall,
  ) => Result;
  inboundUnansweredWithDialStepVoicemailWithoutRecording: (
    contactInteraction: ContactInteractionPhoneCall,
  ) => Result;
  inboundUnansweredWithoutDialStep: (
    contactInteraction: ContactInteractionPhoneCall,
  ) => Result;
  inboundOtherStatusDialStepMenu: (
    contactInteraction: ContactInteractionPhoneCall,
  ) => Result;
  inboundOtherStatusDialStepMessage: (
    contactInteraction: ContactInteractionPhoneCall,
  ) => Result;
  inboundOtherStatusDialStepDial: (
    contactInteraction: ContactInteractionPhoneCall,
  ) => Result;
  inboundOtherStatusDialStepVoicemailWithRecording: (
    contactInteraction: ContactInteractionPhoneCall,
  ) => Result;
  inboundOtherStatusDialStepVoicemailWithoutRecording: (
    contactInteraction: ContactInteractionPhoneCall,
  ) => Result;
  inboundOtherStatusNoStep: (contactInteraction: ContactInteractionPhoneCall) => Result;
  outboundManual: (contactInteraction: ContactInteractionPhoneCall) => Result;
  outboundAnswered: (contactInteraction: ContactInteractionPhoneCall) => Result;
  outboundWithCancelReason: (contactInteraction: ContactInteractionPhoneCall) => Result;
  outboundUnansweredWithBusyReason: (
    contactInteraction: ContactInteractionPhoneCall,
  ) => Result;
  outboundUnansweredWithFailedReason: (
    contactInteraction: ContactInteractionPhoneCall,
  ) => Result;
  outboundUnansweredWithOtherReason: (
    contactInteraction: ContactInteractionPhoneCall,
  ) => Result;
  outboundOtherStatus: (contactInteraction: ContactInteractionPhoneCall) => Result;
  inboundAnsweredWithParticipatingEntityInProgress: (
    contactInteraction: ContactInteractionPhoneCall & {
      participatingEntity: ContactInteractionParticipatingEntityEdge;
    },
  ) => Result;
  inboundAnsweredWithoutParticipatingEntityInProgress: (
    contactInteraction: ContactInteractionPhoneCall,
  ) => Result;
  inboundInProgress: (contactInteraction: ContactInteractionPhoneCall) => Result;
  outboundInProgress: (contactInteraction: ContactInteractionPhoneCall) => Result;
};

type ContactInteractionEmailHandler<Result> = {
  inboundWithParticipatingEntity: (
    contactInteraction: ContactInteractionEmail & {
      participatingEntity: ContactInteractionParticipatingEntityEdge;
    },
  ) => Result;
  inboundWithoutParticipatingEntity: (
    contactInteraction: ContactInteractionEmail & { participatingEntity?: null },
  ) => Result;
  outbound: (contactInteraction: ContactInteractionEmail) => Result;
};

type ContactInteractionFacebookMessengerHandler<Result> = {
  inboundWithParticipatingEntity: (
    contactInteraction: ContactInteractionFacebookMessenger & {
      participatingEntity: ContactInteractionParticipatingEntityEdge;
    },
  ) => Result;
  inboundWithoutParticipatingEntity: (
    contactInteraction: ContactInteractionFacebookMessenger & {
      participatingEntity?: null;
    },
  ) => Result;
  outbound: (contactInteraction: ContactInteractionFacebookMessenger) => Result;
};

type ContactInteractionInstagramHandler<Result> = {
  inboundWithParticipatingEntity: (
    contactInteraction: ContactInteractionInstagram & {
      participatingEntity: ContactInteractionParticipatingEntityEdge;
    },
  ) => Result;
  inboundWithoutParticipatingEntity: (
    contactInteraction: ContactInteractionInstagram & {
      participatingEntity?: null;
    },
  ) => Result;
  outbound: (contactInteraction: ContactInteractionInstagram) => Result;
};

type ContactInteractionSmsHandler<Result> = {
  inboundWithParticipatingEntity: (
    contactInteraction: ContactInteractionSms & {
      participatingEntity: ContactInteractionParticipatingEntityEdge;
    },
  ) => Result;
  inboundWithoutParticipatingEntity: (
    contactInteraction: ContactInteractionSms & { participatingEntity?: null },
  ) => Result;
  outbound: (contactInteraction: ContactInteractionSms) => Result;
};

type ContactInteractionWhatsappHandler<Result> = {
  inboundWithParticipatingEntity: (
    contactInteraction: ContactInteractionWhatsapp & {
      participatingEntity: ContactInteractionParticipatingEntityEdge;
    },
  ) => Result;
  inboundWithoutParticipatingEntity: (
    contactInteraction: ContactInteractionWhatsapp & { participatingEntity?: null },
  ) => Result;
  outbound: (contactInteraction: ContactInteractionWhatsapp) => Result;
};

type ContactInteractionInPersonHandlers<Result> = {
  withParticipatingEntity: (
    contactInteraction: ContactInteractionInPerson & {
      participatingEntity: ContactInteractionParticipatingEntityEdge;
    },
  ) => Result;
  withoutParticipatingEntity: (
    contactInteraction: ContactInteractionInPerson & { participatingEntity?: null },
  ) => Result;
};

type GenericHandler<ContactInteraction, Result> = (
  contactInteraction: ContactInteraction,
) => Result;

export type Handlers<ContactInteraction, Result> = {
  sms: ContactInteractionSmsHandler<Result> | GenericHandler<ContactInteraction, Result>;
  email:
    | ContactInteractionEmailHandler<Result>
    | GenericHandler<ContactInteraction, Result>;
  facebookMessenger:
    | ContactInteractionFacebookMessengerHandler<Result>
    | GenericHandler<ContactInteraction, Result>;
  whatsapp:
    | ContactInteractionWhatsappHandler<Result>
    | GenericHandler<ContactInteraction, Result>;
  instagram:
    | ContactInteractionInstagramHandler<Result>
    | GenericHandler<ContactInteraction, Result>;
  inPerson:
    | ContactInteractionInPersonHandlers<Result>
    | GenericHandler<ContactInteraction, Result>;
  phone:
    | ContactInteractionPhoneCallHandlers<Result>
    | GenericHandler<ContactInteraction, Result>;
  facebookAd: GenericHandler<ContactInteraction, Result>;
  unknown: GenericHandler<ContactInteraction, Result>;
};

export const inboundPhoneCallInteractionPatterns = {
  inboundManualWithParticipatingEntity: {
    direction: 'INBOUND',
    isCreationAutomatic: false,
    participatingEntity: P.not(P.nullish),
  } as const,
  inboundManualWithoutParticipatingEntity: {
    direction: 'INBOUND',
    isCreationAutomatic: false,
    participatingEntity: P.nullish,
  } as const,
  inboundAnsweredWithParticipatingEntity: {
    direction: 'INBOUND',
    isCreationAutomatic: true,
    dialStatus: 'ANSWERED',
    participatingEntity: P.not(P.nullish),
  } as const,
  inboundAnsweredWithoutParticipatingEntity: {
    direction: 'INBOUND',
    isCreationAutomatic: true,
    dialStatus: 'ANSWERED',
    participatingEntity: P.nullish,
  } as const,
  inboundUnansweredWithDialStepMenu: {
    direction: 'INBOUND',
    isCreationAutomatic: true,
    dialStatus: 'UNANSWERED',
    callDetail: {
      inboundLastStep: 'MENU',
    },
  } as const,
  inboundUnansweredWithDialStepMessage: {
    direction: 'INBOUND',
    isCreationAutomatic: true,
    dialStatus: 'UNANSWERED',
    callDetail: {
      inboundLastStep: 'MESSAGE',
    },
  } as const,
  inboundUnansweredWithDialStepDial: {
    direction: 'INBOUND',
    isCreationAutomatic: true,
    dialStatus: 'UNANSWERED',
    callDetail: {
      inboundLastStep: 'DIAL',
    },
  } as const,
  inboundUnansweredWithDialStepVoicemailWithRecording: {
    direction: 'INBOUND',
    isCreationAutomatic: true,
    dialStatus: 'UNANSWERED',
    callDetail: {
      inboundLastStep: 'VOICEMAIL',
    },
    recording: P.not(P.nullish),
  } as const,
  inboundUnansweredWithDialStepVoicemailWithoutRecording: {
    direction: 'INBOUND',
    isCreationAutomatic: true,
    dialStatus: 'UNANSWERED',
    callDetail: {
      inboundLastStep: 'VOICEMAIL',
    },
    recording: P.nullish,
  } as const,
  inboundUnansweredWithoutDialStep: {
    direction: 'INBOUND',
    isCreationAutomatic: true,
    dialStatus: 'UNANSWERED',
    callDetail: {
      inboundLastStep: P.nullish,
    },
  } as const,
  inboundOtherStatusDialStepMenu: {
    direction: 'INBOUND',
    isCreationAutomatic: true,
    dialStatus: P.nullish,
    callDetail: {
      inboundLastStep: 'MENU',
    },
  } as const,
  inboundOtherStatusDialStepMessage: {
    direction: 'INBOUND',
    isCreationAutomatic: true,
    dialStatus: P.nullish,
    callDetail: {
      inboundLastStep: 'MESSAGE',
    },
  } as const,
  inboundOtherStatusDialStepDial: {
    direction: 'INBOUND',
    isCreationAutomatic: true,
    dialStatus: P.nullish,
    callDetail: {
      inboundLastStep: 'DIAL',
    },
  } as const,
  inboundOtherStatusDialStepVoicemailWithRecording: {
    direction: 'INBOUND',
    isCreationAutomatic: true,
    dialStatus: P.nullish,
    callDetail: {
      inboundLastStep: 'VOICEMAIL',
    },
    recording: P.not(P.nullish),
  } as const,
  inboundOtherStatusDialStepVoicemailWithoutRecording: {
    direction: 'INBOUND',
    isCreationAutomatic: true,
    dialStatus: P.nullish,
    callDetail: {
      inboundLastStep: 'VOICEMAIL',
    },
    recording: P.nullish,
  } as const,
  inboundOtherStatusNoStep: {
    direction: 'INBOUND',
    isCreationAutomatic: true,
    dialStatus: P.nullish,
    callDetail: {
      inboundLastStep: P.nullish,
    },
  } as const,
  inboundAnsweredWithParticipatingEntityInProgress: {
    direction: 'INBOUND',
    isCreationAutomatic: true,
    dialStatus: 'ANSWERED',
    participatingEntity: P.not(P.nullish),
    callDetail: {
      isInProgress: true,
    },
  } as const,
  inboundAnsweredWithoutParticipatingEntityInProgress: {
    direction: 'INBOUND',
    isCreationAutomatic: true,
    dialStatus: 'ANSWERED',
    participatingEntity: P.nullish,
    callDetail: {
      isInProgress: true,
    },
  } as const,
  inboundInProgress: {
    direction: 'INBOUND',
    isCreationAutomatic: true,
    callDetail: {
      isInProgress: true,
    },
  } as const,
};

export const matchContactInteractionPhoneCallExhaustive = <Result>(
  contactInteraction: ContactInteractionPhoneCall,
  handler: ContactInteractionPhoneCallHandlers<Result>,
) => {
  return match(contactInteraction)
    .with(
      {
        direction: 'OUTBOUND',
        isCreationAutomatic: true,
        callDetail: {
          isInProgress: true,
        },
      },
      handler.outboundInProgress,
    )
    .with(
      {
        direction: 'OUTBOUND',
        isCreationAutomatic: true,
        callDetail: {
          outboundDialUnansweredReason: 'CANCELED',
        },
      },
      handler.outboundWithCancelReason,
    )
    .with(
      {
        direction: 'OUTBOUND',
        isCreationAutomatic: true,
        dialStatus: 'UNANSWERED',
        callDetail: {
          outboundDialUnansweredReason: 'BUSY',
        },
      },
      handler.outboundUnansweredWithBusyReason,
    )
    .with(
      {
        direction: 'OUTBOUND',
        isCreationAutomatic: true,
        dialStatus: 'UNANSWERED',
        callDetail: {
          outboundDialUnansweredReason: 'FAILED',
        },
      },
      handler.outboundUnansweredWithFailedReason,
    )
    .with(
      {
        direction: 'OUTBOUND',
        isCreationAutomatic: true,
        dialStatus: 'UNANSWERED',
        callDetail: {
          outboundDialUnansweredReason: P.union('NO_ANSWER', P.nullish),
        },
      },
      handler.outboundUnansweredWithOtherReason,
    )
    .with(
      {
        direction: 'OUTBOUND',
        isCreationAutomatic: true,
        dialStatus: P.nullish,
        callDetail: {
          outboundDialUnansweredReason: P.nullish,
        },
      },
      handler.outboundOtherStatus,
    )
    .with({ direction: 'OUTBOUND', isCreationAutomatic: false }, handler.outboundManual)
    .with(
      { direction: 'OUTBOUND', isCreationAutomatic: true, dialStatus: 'ANSWERED' },
      handler.outboundAnswered,
    )
    .with(
      inboundPhoneCallInteractionPatterns.inboundManualWithParticipatingEntity,
      handler.inboundManualWithParticipatingEntity,
    )
    .with(
      inboundPhoneCallInteractionPatterns.inboundManualWithoutParticipatingEntity,
      handler.inboundManualWithoutParticipatingEntity,
    )
    .with(
      inboundPhoneCallInteractionPatterns.inboundAnsweredWithParticipatingEntityInProgress,
      handler.inboundAnsweredWithParticipatingEntityInProgress,
    )
    .with(
      inboundPhoneCallInteractionPatterns.inboundAnsweredWithParticipatingEntity,
      handler.inboundAnsweredWithParticipatingEntity,
    )
    .with(
      inboundPhoneCallInteractionPatterns.inboundAnsweredWithoutParticipatingEntityInProgress,
      handler.inboundAnsweredWithoutParticipatingEntityInProgress,
    )
    .with(
      inboundPhoneCallInteractionPatterns.inboundAnsweredWithoutParticipatingEntity,
      handler.inboundAnsweredWithoutParticipatingEntity,
    )
    .with(
      inboundPhoneCallInteractionPatterns.inboundInProgress,
      handler.inboundInProgress,
    )
    .with(
      inboundPhoneCallInteractionPatterns.inboundUnansweredWithDialStepMenu,
      handler.inboundUnansweredWithDialStepMenu,
    )
    .with(
      inboundPhoneCallInteractionPatterns.inboundUnansweredWithDialStepMessage,
      handler.inboundUnansweredWithDialStepMessage,
    )
    .with(
      inboundPhoneCallInteractionPatterns.inboundUnansweredWithDialStepDial,
      handler.inboundUnansweredWithDialStepDial,
    )
    .with(
      inboundPhoneCallInteractionPatterns.inboundUnansweredWithDialStepVoicemailWithRecording,
      handler.inboundUnansweredWithDialStepVoicemailWithRecording,
    )
    .with(
      inboundPhoneCallInteractionPatterns.inboundUnansweredWithDialStepVoicemailWithoutRecording,
      handler.inboundUnansweredWithDialStepVoicemailWithoutRecording,
    )
    .with(
      inboundPhoneCallInteractionPatterns.inboundUnansweredWithoutDialStep,
      handler.inboundUnansweredWithoutDialStep,
    )
    .with(
      inboundPhoneCallInteractionPatterns.inboundOtherStatusDialStepMenu,
      handler.inboundOtherStatusDialStepMenu,
    )
    .with(
      inboundPhoneCallInteractionPatterns.inboundOtherStatusDialStepMessage,
      handler.inboundOtherStatusDialStepMessage,
    )
    .with(
      inboundPhoneCallInteractionPatterns.inboundOtherStatusDialStepDial,
      handler.inboundOtherStatusDialStepDial,
    )
    .with(
      inboundPhoneCallInteractionPatterns.inboundOtherStatusDialStepVoicemailWithRecording,
      handler.inboundOtherStatusDialStepVoicemailWithRecording,
    )
    .with(
      inboundPhoneCallInteractionPatterns.inboundOtherStatusDialStepVoicemailWithoutRecording,
      handler.inboundOtherStatusDialStepVoicemailWithoutRecording,
    )
    .with(
      inboundPhoneCallInteractionPatterns.inboundOtherStatusNoStep,
      handler.inboundOtherStatusNoStep,
    )
    .otherwise((contactInteraction) =>
      match(contactInteraction)
        .with({ direction: 'OUTBOUND' }, handler.defaultOutbound)
        .otherwise(handler.defaultInbound),
    );
};

export const matchContactInteractionInPersonExhaustive = <Result>(
  contactInteraction: ContactInteractionInPerson,
  handler: ContactInteractionInPersonHandlers<Result>,
) => {
  return match(contactInteraction)
    .with({ participatingEntity: P.not(P.nullish) }, handler.withParticipatingEntity)
    .with({ participatingEntity: P.nullish }, handler.withoutParticipatingEntity)
    .run();
};

export const matchContactInteractionSmsExhaustive = <Result>(
  contactInteraction: ContactInteractionSms,
  handler: ContactInteractionSmsHandler<Result>,
) => {
  return match(contactInteraction)
    .with(
      { direction: 'INBOUND', participatingEntity: P.not(P.nullish) },
      handler.inboundWithParticipatingEntity,
    )
    .with(
      { direction: 'INBOUND', participatingEntity: P.nullish },
      handler.inboundWithoutParticipatingEntity,
    )
    .with({ direction: 'OUTBOUND' }, handler.outbound)
    .run();
};

export const matchContactInteractionWhatsappExhaustive = <Result>(
  contactInteraction: ContactInteractionWhatsapp,
  handler: ContactInteractionWhatsappHandler<Result>,
) => {
  return match(contactInteraction)
    .with(
      { direction: 'INBOUND', participatingEntity: P.not(P.nullish) },
      handler.inboundWithParticipatingEntity,
    )
    .with(
      { direction: 'INBOUND', participatingEntity: P.nullish },
      handler.inboundWithoutParticipatingEntity,
    )
    .with({ direction: 'OUTBOUND' }, handler.outbound)
    .run();
};

export const matchContactInteractionEmailExhaustive = <Result>(
  contactInteraction: ContactInteractionEmail,
  handler: ContactInteractionEmailHandler<Result>,
) => {
  return match(contactInteraction)
    .with(
      { direction: 'INBOUND', participatingEntity: P.not(P.nullish) },
      handler.inboundWithParticipatingEntity,
    )
    .with(
      { direction: 'INBOUND', participatingEntity: P.nullish },
      handler.inboundWithoutParticipatingEntity,
    )
    .with({ direction: 'OUTBOUND' }, handler.outbound)
    .run();
};

export const matchContactInteractionFacebookMessengerExhaustive = <Result>(
  contactInteraction: ContactInteractionFacebookMessenger,
  handler: ContactInteractionFacebookMessengerHandler<Result>,
) => {
  return match(contactInteraction)
    .with(
      { direction: 'INBOUND', participatingEntity: P.not(P.nullish) },
      handler.inboundWithParticipatingEntity,
    )
    .with(
      { direction: 'INBOUND', participatingEntity: P.nullish },
      handler.inboundWithoutParticipatingEntity,
    )
    .with({ direction: 'OUTBOUND' }, handler.outbound)
    .run();
};

export const matchContactInteractionInstagramExhaustive = <Result>(
  contactInteraction: ContactInteractionInstagram,
  handler: ContactInteractionInstagramHandler<Result>,
) => {
  return match(contactInteraction)
    .with(
      { direction: 'INBOUND', participatingEntity: P.not(P.nullish) },
      handler.inboundWithParticipatingEntity,
    )
    .with(
      { direction: 'INBOUND', participatingEntity: P.nullish },
      handler.inboundWithoutParticipatingEntity,
    )
    .with({ direction: 'OUTBOUND' }, handler.outbound)
    .run();
};

export const matchContactInteractionFacebookAdExhaustive = <Result>(
  contactInteraction: ContactInteractionFacebookAd,
  handler: GenericHandler<ContactInteractionFacebookAd, Result>,
) => {
  return handler(contactInteraction);
};

export const contactInteractionPatterns = {
  sms: { __typename: 'ContactInteractionSms' } as const,
  whatsapp: { __typename: 'ContactInteractionWhatsapp' } as const,
  email: { __typename: 'ContactInteractionEmail' } as const,
  phone: { __typename: 'ContactInteractionPhoneCall' } as const,
  inPerson: { __typename: 'ContactInteractionInPerson' } as const,
  facebookMessenger: { __typename: 'ContactInteractionFacebookMessenger' } as const,
  instagram: { __typename: 'ContactInteractionInstagram' } as const,
  facebookAd: { __typename: 'ContactInteractionFacebookAd' } as const,
};

export const matchContactInteractionExhaustive = <Result>(
  contactInteraction: ContactInteractionUnion,
  handlers: {
    sms:
      | ContactInteractionSmsHandler<Result>
      | GenericHandler<ContactInteractionSms, Result>;
    whatsapp:
      | ContactInteractionWhatsappHandler<Result>
      | GenericHandler<ContactInteractionWhatsapp, Result>;
    email:
      | ContactInteractionEmailHandler<Result>
      | GenericHandler<ContactInteractionEmail, Result>;
    phone:
      | ContactInteractionPhoneCallHandlers<Result>
      | GenericHandler<ContactInteractionPhoneCall, Result>;
    inPerson:
      | ContactInteractionInPersonHandlers<Result>
      | GenericHandler<ContactInteractionInPerson, Result>;
    facebookMessenger:
      | ContactInteractionFacebookMessengerHandler<Result>
      | GenericHandler<ContactInteractionFacebookMessenger, Result>;
    instagram:
      | ContactInteractionInstagramHandler<Result>
      | GenericHandler<ContactInteractionInstagram, Result>;
    facebookAd: GenericHandler<ContactInteractionFacebookAd, Result>;
    unknown: GenericHandler<ContactInteractionUnion, Result>;
  },
): Result => {
  const {
    email,
    sms,
    phone,
    inPerson,
    facebookMessenger,
    facebookAd,
    whatsapp,
    instagram,
  } = handlers;

  return match(contactInteraction)
    .with(contactInteractionPatterns.phone, (contactInteraction) => {
      if (typeof phone === 'function') {
        return phone(contactInteraction);
      }

      return matchContactInteractionPhoneCallExhaustive(contactInteraction, phone);
    })
    .with(contactInteractionPatterns.inPerson, (contactInteraction) => {
      if (typeof inPerson === 'function') {
        return inPerson(contactInteraction);
      }

      return matchContactInteractionInPersonExhaustive(contactInteraction, inPerson);
    })
    .with(contactInteractionPatterns.sms, (contactInteraction) => {
      if (typeof sms === 'function') {
        return sms(contactInteraction);
      }

      return matchContactInteractionSmsExhaustive(contactInteraction, sms);
    })
    .with(contactInteractionPatterns.whatsapp, (contactInteraction) => {
      if (typeof whatsapp === 'function') {
        return whatsapp(contactInteraction);
      }

      return matchContactInteractionWhatsappExhaustive(contactInteraction, whatsapp);
    })
    .with(contactInteractionPatterns.email, (contactInteraction) => {
      if (typeof email === 'function') {
        return email(contactInteraction);
      }

      return matchContactInteractionEmailExhaustive(contactInteraction, email);
    })
    .with(contactInteractionPatterns.facebookMessenger, (contactInteraction) => {
      if (typeof facebookMessenger === 'function') {
        return facebookMessenger(contactInteraction);
      }

      return matchContactInteractionFacebookMessengerExhaustive(
        contactInteraction,
        facebookMessenger,
      );
    })
    .with(contactInteractionPatterns.instagram, (contactInteraction) => {
      if (typeof instagram === 'function') {
        return instagram(contactInteraction);
      }

      return matchContactInteractionInstagramExhaustive(contactInteraction, instagram);
    })
    .with(contactInteractionPatterns.facebookAd, (contactInteraction) =>
      matchContactInteractionFacebookAdExhaustive(contactInteraction, facebookAd),
    )
    .otherwise(() => handlers.unknown(contactInteraction));
};
