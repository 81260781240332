import React, { memo, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { up } from 'styled-breakpoints';
import styled from 'styled-components';
import { match, P } from 'ts-pattern';
import { ColorPaletteItem } from '@lgg/isomorphic';
import {
  ContactInteractionUnion,
  ContactTimelineUnion,
  Contact,
  ContactTimelineUser,
  PhoneNumber,
  ContactInteractionParticipatingEntityEdge,
} from '@lgg/isomorphic/types/__generated__/graphql';
import { matchContactInteractionExhaustive } from '@lgg/isomorphic/utils/match-contact-interaction';
import { getAppointmentStatusTransKey } from 'src/components/domain/appointments/helpers/get-appointment-status-trans-key';
import { InProgressCallIndicator } from 'src/components/domain/contact-interaction/in-progress-call-indicator';
import { getTaskStatusTransKey } from 'src/components/domain/tasks/helpers/get-task-status-trans-key';
import { Icon } from 'src/components/general/icon';
import { FlexColumn } from 'src/components/layout/flex-column';
import { FlexRow } from 'src/components/layout/flex-row';
import { openRemoteModalWrapper } from 'src/components/pages/legacy/components/open-legacy-remote-modal-link';
import { useCurrentUserId } from 'src/hooks/use-current-user';
import { useFormatDate } from 'src/hooks/use-format-date';
import { useUrls } from 'src/hooks/use-urls';

const StyledContactTimelineItem = styled(FlexRow)`
  align-items: flex-start;
  border-bottom: 1px solid ${({ theme }) => theme.colors.porcelain};
  color: ${({ theme }) => theme.colors.flint};
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 13px;
  justify-content: flex-start;
  line-height: 15px;
  padding: 12px 22px 12px 20px;

  a,
  span.link {
    color: ${({ theme }) => theme.colors.gogo};
    cursor: pointer;
    display: inline;
    font-family: ${({ theme }) => theme.font.medium};
  }

  span {
    color: ${({ theme }) => theme.colors.smalt};
  }

  ${up('md')} {
    font-size: 14px;
    line-height: 17px;
    padding-left: 0;
    padding-right: 0;
    margin-left: 20px;
    margin-right: 20px;
  }
`;

const StyledIcon = styled(Icon)<{ color: ColorPaletteItem }>`
  margin-right: 15px;

  svg {
    height: 16px;
    width: 16px;

    path {
      fill: ${({ theme, color }) => theme.colors[color]};
    }
  }
`;

const CreationDate = styled.span`
  && {
    color: ${({ theme }) => theme.colors.flint};
    font-family: ${({ theme }) => theme.font.regular};
    font-size: 12px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: 14px;
    margin-top: 3px;
    text-align: left;

    ${up('md')} {
      margin-left: 20px;
      white-space: nowrap;
    }
  }
`;

const ContentContainer = styled(FlexColumn)`
  flex: 1;

  ${up('md')} {
    flex-direction: row;
  }
`;

const TextWrapper = styled.div`
  flex: 1;
`;

type ContactModalTimelineItemProps = {
  contactTimeline: ContactTimelineUnion;
  contact: Contact;
};

export const ContactModalTimelineItem = memo<ContactModalTimelineItemProps>(
  ({ contactTimeline, contact }) => {
    const { t } = useTranslation(['common', 'contactTimeline', 'tasks', 'appointments']);
    const userId = useCurrentUserId();
    const { formatDueDate } = useFormatDate();
    const { getContactModalUrl } = useUrls();
    const { getTaskModalUrl, getScheduleModalUrl, getLegacyContactEditNoteUrl } =
      useUrls();

    const icon = useMemo(() => {
      let iconConfig: { type: string; color: ColorPaletteItem } | null = null;

      switch (contactTimeline.__typename) {
        case 'ContactTimelineContactCreated':
        case 'ContactTimelineContactStatusUpdated':
        case 'ContactTimelineContactUpdated':
          iconConfig = { type: 'contact', color: 'complementaryDarkColor1' };
          break;
        case 'ContactTimelineTaskCreated':
        case 'ContactTimelineTaskStatusUpdated':
        case 'ContactTimelineTaskDeleted':
          iconConfig = { type: 'task18', color: 'secondaryMintDark' };
          break;
        case 'ContactTimelineScheduleCreated':
        case 'ContactTimelineScheduleStatusUpdated':
        case 'ContactTimelineScheduleDeleted':
          iconConfig = { type: 'schedule18', color: 'secondaryPeriwinkleDark' };
          break;
        case 'ContactTimelineNoteCreated':
        case 'ContactTimelineNoteDeleted':
          iconConfig = { type: 'note18', color: 'secondaryFlowerDark' };
          break;
        case 'ContactTimelineContactInteraction':
          if (!contactTimeline.contactInteraction) {
            iconConfig = { type: 'interaction', color: 'secondaryTopazDark' };
            break;
          }

          switch (contactTimeline.contactInteraction.__typename) {
            case 'ContactInteractionInPerson':
              iconConfig = { type: 'inPersonLog', color: 'secondaryTopazDark' };
              break;
            case 'ContactInteractionUnknown':
              iconConfig = { type: 'interaction', color: 'secondaryTopazDark' };
              break;
            case 'ContactInteractionSms':
              iconConfig = { type: 'sms', color: 'secondaryTopazDark' };
              break;
            case 'ContactInteractionFacebookMessenger':
              iconConfig = { type: 'facebookMessenger', color: 'secondaryTopazDark' };
              break;
            case 'ContactInteractionWhatsapp':
              iconConfig = { type: 'whatsapp', color: 'secondaryTopazDark' };
              break;
            case 'ContactInteractionInstagram':
              iconConfig = { type: 'instagramAlt', color: 'secondaryTopazDark' };
              break;
            case 'ContactInteractionEmail':
              iconConfig = { type: 'email', color: 'secondaryTopazDark' };
              break;
            case 'ContactInteractionPhoneCall':
              iconConfig = { type: 'phone', color: 'secondaryTopazDark' };
              break;
            case 'ContactInteractionFacebookAd':
              iconConfig = { type: 'iconFacebookForm', color: 'secondaryTopazDark' };
              break;
          }
          break;
      }

      if (iconConfig) {
        return <StyledIcon {...iconConfig} data-lgg-id="icon" />;
      }

      return null;
    }, [contactTimeline]);

    const text = useMemo(() => {
      const resolveOriginatorLabel = (
        originator:
          | ContactTimelineUser
          | ContactInteractionParticipatingEntityEdge
          | null
          | undefined
          | PhoneNumber,
      ) => {
        if (!originator) {
          return 'TBD';
        }

        return match(originator)
          .with(
            P.union({ __typename: 'ContactInteractionParticipatingEntityEdge' }),
            (entity) => {
              if (entity.type === 'AGENT' && entity.id === userId) {
                return t('common:you');
              }

              return entity.label;
            },
          )
          .with({ __typename: 'PhoneNumber' }, (phone) => phone.national)
          .with({ __typename: 'ContactTimelineUser' }, (user) => {
            if (user.id === userId) {
              return t('common:you');
            }

            return user.fullName;
          })
          .exhaustive();
      };

      const inProgressIndicator = <InProgressCallIndicator />;

      const resolveContactInteractionComponents = (
        contactInteraction: ContactInteractionUnion,
      ) => {
        const contactInteractionUrl = getContactModalUrl(contact.id, {
          contactInteractionId: contactInteraction.id,
          conversationId: contact.lastConversation.id,
        });

        return {
          strong: <span />,
          modalLink: (
            <Link
              className="link"
              replace={true}
              to={contactInteractionUrl}
              data-lgg-id={`contact-interaction-link-id-${contactInteraction.id}`}
            />
          ),
        };
      };

      return match(contactTimeline)
        .with({ __typename: 'ContactTimelineContactCreated' }, () => {
          return (
            <Trans
              i18nKey="contactTimeline:logs.contact.created"
              components={[<span />]}
            />
          );
        })
        .with(
          { __typename: 'ContactTimelineContactStatusUpdated' },
          (contactTimeline) => {
            return (
              <Trans
                i18nKey="contactTimeline:logs.contact.statusUpdated"
                values={{
                  originator: resolveOriginatorLabel(contactTimeline.originator),
                  oldStatus: contactTimeline.oldStatus.name,
                  newStatus: contactTimeline.newStatus.name,
                }}
                components={[<span />]}
              />
            );
          },
        )
        .with({ __typename: 'ContactTimelineContactUpdated' }, (contactTimeline) => {
          return (
            <Trans
              i18nKey="contactTimeline:logs.contact.updated"
              values={{
                originator: resolveOriginatorLabel(contactTimeline.originator),
              }}
              components={[<span />]}
            />
          );
        })
        .with({ __typename: 'ContactTimelineTaskCreated' }, (contactTimeline) => {
          return (
            <Trans
              i18nKey="contactTimeline:logs.task.created"
              values={{
                originator: resolveOriginatorLabel(contactTimeline.originator),
                title: contactTimeline.task.title,
              }}
              components={{
                strong: <span />,
                modalLink: <Link to={getTaskModalUrl(contactTimeline.task.id)} />,
              }}
            />
          );
        })
        .with({ __typename: 'ContactTimelineTaskStatusUpdated' }, (contactTimeline) => {
          const oldStatus = t(getTaskStatusTransKey(contactTimeline.oldTaskStatus));
          const newStatus = t(getTaskStatusTransKey(contactTimeline.newTaskStatus));

          return (
            <Trans
              i18nKey="contactTimeline:logs.task.statusUpdated"
              values={{
                originator: resolveOriginatorLabel(contactTimeline.originator),
                title: contactTimeline.task.title,
                oldStatus,
                newStatus,
              }}
              components={{
                strong: <span />,
                modalLink: <Link to={getTaskModalUrl(contactTimeline.task.id)} />,
              }}
            />
          );
        })
        .with({ __typename: 'ContactTimelineTaskDeleted' }, (contactTimeline) => {
          return (
            <Trans
              i18nKey="contactTimeline:logs.task.deleted"
              values={{
                originator: resolveOriginatorLabel(contactTimeline.originator),
                title: contactTimeline.taskTitle,
              }}
              components={{
                strong: <span />,
              }}
            />
          );
        })
        .with({ __typename: 'ContactTimelineScheduleCreated' }, (contactTimeline) => {
          return (
            <Trans
              i18nKey="contactTimeline:logs.appointment.created"
              values={{
                originator: resolveOriginatorLabel(contactTimeline.originator),
                title: contactTimeline.schedule.title,
              }}
              components={[
                <span />,
                <Link
                  to={getScheduleModalUrl(contactTimeline.schedule.id, contact.id)}
                />,
              ]}
            />
          );
        })
        .with(
          { __typename: 'ContactTimelineScheduleStatusUpdated' },

          (contactTimeline) => {
            const oldStatus = t(
              getAppointmentStatusTransKey(contactTimeline.oldScheduleStatus),
            );
            const newStatus = t(
              getAppointmentStatusTransKey(contactTimeline.newScheduleStatus),
            );

            return (
              <Trans
                i18nKey="contactTimeline:logs.appointment.statusUpdated"
                values={{
                  originator: resolveOriginatorLabel(contactTimeline.originator),
                  title: contactTimeline.schedule.title,
                  oldStatus,
                  newStatus,
                }}
                components={{
                  strong: <span />,
                  modalLink: (
                    <Link
                      to={getScheduleModalUrl(contactTimeline.schedule.id, contact.id)}
                    />
                  ),
                }}
              />
            );
          },
        )
        .with({ __typename: 'ContactTimelineScheduleDeleted' }, (contactTimeline) => {
          return (
            <Trans
              i18nKey="contactTimeline:logs.appointment.deleted"
              values={{
                originator: resolveOriginatorLabel(contactTimeline.originator),
                title: contactTimeline.appointmentTitle,
              }}
              components={{
                strong: <span />,
              }}
            />
          );
        })
        .with({ __typename: 'ContactTimelineNoteCreated' }, (contactTimeline) => {
          return (
            <Trans
              i18nKey="contactTimeline:logs.note.created"
              values={{
                originator: resolveOriginatorLabel(contactTimeline.originator),
              }}
              components={{
                strong: <span />,
                noteLink: (
                  <span
                    className="link"
                    onClick={() =>
                      openRemoteModalWrapper(
                        getLegacyContactEditNoteUrl(contact.id, contactTimeline.note.id),
                      )
                    }
                  />
                ),
              }}
            />
          );
        })
        .with({ __typename: 'ContactTimelineNoteDeleted' }, (contactTimeline) => {
          return (
            <Trans
              i18nKey="contactTimeline:logs.note.deleted"
              values={{
                originator: resolveOriginatorLabel(contactTimeline.originator),
              }}
              components={{ strong: <span /> }}
            />
          );
        })
        .with({ __typename: 'ContactTimelineContactInteraction' }, (contactTimeline) => {
          if (!contactTimeline.contactInteraction) {
            return (
              <Trans
                i18nKey="contactTimeline:logs.contactInteraction.deletedInteraction"
                values={{
                  originator: resolveOriginatorLabel(contactTimeline.originator),
                }}
                components={{ strong: <span /> }}
              />
            );
          }

          const components = resolveContactInteractionComponents(
            contactTimeline.contactInteraction,
          );

          const getInboundAnsweredPhoneByTitle = () => {
            return (
              <Trans
                i18nKey="contactTimeline:logs.contactInteraction.call.inboundAnsweredWithoutParticipatingEntity"
                components={components}
              />
            );
          };

          return matchContactInteractionExhaustive(contactTimeline.contactInteraction, {
            unknown: () => (
              <Trans i18nKey="contactTimeline:logs.contactInteraction.unknown" />
            ),
            facebookAd: () => (
              <Trans
                i18nKey="contactTimeline:logs.contactInteraction.facebookAd"
                components={components}
              />
            ),
            phone: {
              defaultInbound: () => (
                <Trans
                  i18nKey="contactTimeline:logs.contactInteraction.call.defaultInbound"
                  components={components}
                />
              ),
              inboundInProgress: () => (
                <>
                  <Trans
                    i18nKey="contactTimeline:logs.contactInteraction.call.defaultInbound"
                    components={components}
                  />
                  {inProgressIndicator}
                </>
              ),
              defaultOutbound: ({ participatingEntity }) => {
                return (
                  <Trans
                    i18nKey="contactTimeline:logs.contactInteraction.call.defaultOutbound"
                    values={{
                      originator: resolveOriginatorLabel(participatingEntity),
                    }}
                    components={components}
                  />
                );
              },
              outboundInProgress: ({ participatingEntity }) => (
                <>
                  <Trans
                    i18nKey="contactTimeline:logs.contactInteraction.call.defaultOutbound"
                    values={{
                      originator: resolveOriginatorLabel(participatingEntity),
                    }}
                    components={components}
                  />
                  {inProgressIndicator}
                </>
              ),
              inboundManualWithParticipatingEntity: ({ participatingEntity }) => (
                <Trans
                  i18nKey="contactTimeline:logs.contactInteraction.call.inboundManualWithParticipatingEntity"
                  values={{
                    participatingEntityLabel: resolveOriginatorLabel(participatingEntity),
                  }}
                  components={components}
                />
              ),
              inboundManualWithoutParticipatingEntity: () => {
                return (
                  <Trans
                    i18nKey="contactTimeline:logs.contactInteraction.call.inboundManualWithoutParticipatingEntity"
                    components={components}
                  />
                );
              },
              inboundAnsweredWithParticipatingEntity: ({ participatingEntity }) => (
                <Trans
                  i18nKey="contactTimeline:logs.contactInteraction.call.inboundAnsweredWithParticipatingEntity"
                  values={{
                    participatingEntityLabel: resolveOriginatorLabel(participatingEntity),
                  }}
                  components={components}
                />
              ),
              inboundAnsweredWithParticipatingEntityInProgress: ({
                participatingEntity,
              }) => (
                <>
                  <Trans
                    i18nKey="contactTimeline:logs.contactInteraction.call.inboundAnsweredWithParticipatingEntity"
                    values={{
                      participatingEntityLabel:
                        resolveOriginatorLabel(participatingEntity),
                    }}
                    components={components}
                  />
                  {inProgressIndicator}
                </>
              ),
              inboundAnsweredWithoutParticipatingEntity: () =>
                getInboundAnsweredPhoneByTitle(),
              inboundAnsweredWithoutParticipatingEntityInProgress: () => (
                <>
                  {getInboundAnsweredPhoneByTitle()}
                  {inProgressIndicator}
                </>
              ),
              inboundUnansweredWithDialStepMenu: () => (
                <Trans
                  i18nKey="contactTimeline:logs.contactInteraction.call.inboundUnansweredWithDialStepMenu"
                  components={components}
                />
              ),
              inboundUnansweredWithDialStepMessage: () => (
                <Trans
                  i18nKey="contactTimeline:logs.contactInteraction.call.inboundUnansweredWithDialStepMessage"
                  components={components}
                />
              ),
              inboundUnansweredWithDialStepDial: () => (
                <Trans
                  i18nKey="contactTimeline:logs.contactInteraction.call.inboundUnansweredWithDialStepDial"
                  components={components}
                />
              ),
              inboundUnansweredWithDialStepVoicemailWithRecording: () => (
                <Trans
                  i18nKey="contactTimeline:logs.contactInteraction.call.inboundUnansweredWithDialStepVoicemailWithRecording"
                  components={components}
                />
              ),
              inboundUnansweredWithDialStepVoicemailWithoutRecording: () => (
                <Trans
                  i18nKey="contactTimeline:logs.contactInteraction.call.inboundUnansweredWithDialStepVoicemailWithoutRecording"
                  components={components}
                />
              ),
              inboundUnansweredWithoutDialStep: () => (
                <Trans
                  i18nKey="contactTimeline:logs.contactInteraction.call.inboundUnansweredWithoutDialStep"
                  components={components}
                />
              ),
              inboundOtherStatusDialStepMenu: () => (
                <Trans
                  i18nKey="contactTimeline:logs.contactInteraction.call.inboundOtherStatusDialStepMenu"
                  components={components}
                />
              ),
              inboundOtherStatusDialStepMessage: () => (
                <Trans
                  i18nKey="contactTimeline:logs.contactInteraction.call.inboundOtherStatusDialStepMessage"
                  components={components}
                />
              ),
              inboundOtherStatusDialStepDial: () => (
                <Trans
                  i18nKey="contactTimeline:logs.contactInteraction.call.inboundOtherStatusDialStepDial"
                  components={components}
                />
              ),
              inboundOtherStatusDialStepVoicemailWithRecording: () => (
                <Trans
                  i18nKey="contactTimeline:logs.contactInteraction.call.inboundOtherStatusDialStepVoicemailWithRecording"
                  components={components}
                />
              ),
              inboundOtherStatusDialStepVoicemailWithoutRecording: () => (
                <Trans
                  i18nKey="contactTimeline:logs.contactInteraction.call.inboundOtherStatusDialStepVoicemailWithoutRecording"
                  components={components}
                />
              ),
              inboundOtherStatusNoStep: () => (
                <Trans
                  i18nKey="contactTimeline:logs.contactInteraction.call.inboundOtherStatusNoStep"
                  components={components}
                />
              ),
              outboundManual: ({ participatingEntity }) => (
                <Trans
                  i18nKey="contactTimeline:logs.contactInteraction.call.outboundManual"
                  values={{
                    originator: resolveOriginatorLabel(participatingEntity),
                  }}
                  components={components}
                />
              ),
              outboundAnswered: ({ participatingEntity }) => (
                <Trans
                  i18nKey="contactTimeline:logs.contactInteraction.call.outboundAnswered"
                  values={{
                    originator: resolveOriginatorLabel(participatingEntity),
                  }}
                  components={components}
                />
              ),
              outboundWithCancelReason: ({ participatingEntity }) => (
                <Trans
                  i18nKey="contactTimeline:logs.contactInteraction.call.outboundWithCancelReason"
                  values={{
                    originator: resolveOriginatorLabel(participatingEntity),
                  }}
                  components={components}
                />
              ),
              outboundUnansweredWithBusyReason: ({ participatingEntity }) => (
                <Trans
                  i18nKey="contactTimeline:logs.contactInteraction.call.outboundUnansweredWithBusyReason"
                  values={{
                    originator: resolveOriginatorLabel(participatingEntity),
                  }}
                  components={components}
                />
              ),
              outboundUnansweredWithFailedReason: ({ participatingEntity }) => (
                <Trans
                  i18nKey="contactTimeline:logs.contactInteraction.call.outboundUnansweredWithFailedReason"
                  values={{
                    originator: resolveOriginatorLabel(participatingEntity),
                  }}
                  components={components}
                />
              ),
              outboundUnansweredWithOtherReason: ({ participatingEntity }) => (
                <Trans
                  i18nKey="contactTimeline:logs.contactInteraction.call.outboundUnansweredWithOtherReason"
                  values={{
                    originator: resolveOriginatorLabel(participatingEntity),
                  }}
                  components={components}
                />
              ),
              outboundOtherStatus: ({ participatingEntity }) => (
                <Trans
                  i18nKey="contactTimeline:logs.contactInteraction.call.outboundOtherStatus"
                  values={{
                    originator: resolveOriginatorLabel(participatingEntity),
                  }}
                  components={components}
                />
              ),
            },
            inPerson: {
              withoutParticipatingEntity: () => (
                <Trans
                  i18nKey="contactTimeline:logs.contactInteraction.inPerson.withoutParticipatingEntity"
                  components={components}
                />
              ),
              withParticipatingEntity: ({ participatingEntity }) => (
                <Trans
                  i18nKey="contactTimeline:logs.contactInteraction.inPerson.withParticipatingEntity"
                  values={{
                    originator: resolveOriginatorLabel(participatingEntity),
                  }}
                  components={components}
                />
              ),
            },
            sms: {
              outbound: ({ participatingEntity }) => (
                <Trans
                  i18nKey="contactTimeline:logs.contactInteraction.sms.outbound"
                  values={{
                    originator: resolveOriginatorLabel(participatingEntity),
                  }}
                  components={components}
                />
              ),
              inboundWithParticipatingEntity: ({ participatingEntity }) => (
                <Trans
                  i18nKey="contactTimeline:logs.contactInteraction.sms.inboundWithParticipatingEntity"
                  values={{
                    originator: resolveOriginatorLabel(participatingEntity),
                  }}
                  components={components}
                />
              ),
              inboundWithoutParticipatingEntity: () => (
                <Trans
                  i18nKey="contactTimeline:logs.contactInteraction.sms.inboundWithoutParticipatingEntity"
                  components={components}
                />
              ),
            },
            whatsapp: {
              outbound: ({ participatingEntity }) => (
                <Trans
                  i18nKey="contactTimeline:logs.contactInteraction.whatsapp.outbound"
                  values={{
                    originator: resolveOriginatorLabel(participatingEntity),
                  }}
                  components={components}
                />
              ),
              inboundWithParticipatingEntity: ({ participatingEntity }) => (
                <Trans
                  i18nKey="contactTimeline:logs.contactInteraction.whatsapp.inboundWithParticipatingEntity"
                  values={{
                    originator: resolveOriginatorLabel(participatingEntity),
                  }}
                  components={components}
                />
              ),
              inboundWithoutParticipatingEntity: () => (
                <Trans
                  i18nKey="contactTimeline:logs.contactInteraction.whatsapp.inboundWithoutParticipatingEntity"
                  components={components}
                />
              ),
            },
            email: {
              outbound: ({ participatingEntity }) => (
                <Trans
                  i18nKey="contactTimeline:logs.contactInteraction.email.outbound"
                  values={{
                    originator: resolveOriginatorLabel(participatingEntity),
                  }}
                  components={components}
                />
              ),
              inboundWithParticipatingEntity: ({ participatingEntity }) => (
                <Trans
                  i18nKey="contactTimeline:logs.contactInteraction.email.inboundWithParticipatingEntity"
                  values={{
                    originator: resolveOriginatorLabel(participatingEntity),
                  }}
                  components={components}
                />
              ),
              inboundWithoutParticipatingEntity: () => (
                <Trans
                  i18nKey="contactTimeline:logs.contactInteraction.email.inboundWithoutParticipatingEntity"
                  components={components}
                />
              ),
            },
            facebookMessenger: {
              outbound: ({ participatingEntity }) => (
                <Trans
                  i18nKey="contactTimeline:logs.contactInteraction.facebookMessenger.outbound"
                  values={{
                    originator: resolveOriginatorLabel(participatingEntity),
                  }}
                  components={components}
                />
              ),
              inboundWithParticipatingEntity: ({ participatingEntity }) => (
                <Trans
                  i18nKey="contactTimeline:logs.contactInteraction.facebookMessenger.inboundWithParticipatingEntity"
                  values={{
                    originator: resolveOriginatorLabel(participatingEntity),
                  }}
                  components={components}
                />
              ),
              inboundWithoutParticipatingEntity: () => (
                <Trans
                  i18nKey="contactTimeline:logs.contactInteraction.facebookMessenger.inboundWithoutParticipatingEntity"
                  components={components}
                />
              ),
            },
            instagram: {
              outbound: ({ participatingEntity }) => (
                <Trans
                  i18nKey="contactTimeline:logs.contactInteraction.instagram.outbound"
                  values={{
                    originator: resolveOriginatorLabel(participatingEntity),
                  }}
                  components={components}
                />
              ),
              inboundWithParticipatingEntity: ({ participatingEntity }) => (
                <Trans
                  i18nKey="contactTimeline:logs.contactInteraction.instagram.inboundWithParticipatingEntity"
                  values={{
                    originator: resolveOriginatorLabel(participatingEntity),
                  }}
                  components={components}
                />
              ),
              inboundWithoutParticipatingEntity: () => (
                <Trans
                  i18nKey="contactTimeline:logs.contactInteraction.instagram.inboundWithoutParticipatingEntity"
                  components={components}
                />
              ),
            },
          });
        })
        .exhaustive();
    }, [
      contactTimeline,
      userId,
      t,
      getTaskModalUrl,
      getScheduleModalUrl,
      contact.id,
      contact.lastConversation.id,
      getLegacyContactEditNoteUrl,
      getContactModalUrl,
    ]);

    return (
      <StyledContactTimelineItem data-lgg-id="contact-modal-timeline-item">
        {icon}
        <ContentContainer>
          <TextWrapper data-lgg-id="text">{text}</TextWrapper>
          <CreationDate data-lgg-id="creation-date">
            {formatDueDate(contactTimeline.occurredAt)}
          </CreationDate>
        </ContentContainer>
      </StyledContactTimelineItem>
    );
  },
);
