import React, { memo, RefObject, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { GroupedVirtuoso, VirtuosoHandle } from 'react-virtuoso';
import { gql } from '@apollo/client';
import { groupBy } from 'lodash';
import { up } from 'styled-breakpoints';
import styled from 'styled-components';
import { match, P } from 'ts-pattern';
import {
  Contact,
  ContactTimelineUnion,
  Query,
  QueryContactTimelineArgs,
} from '@lgg/isomorphic/types/__generated__/graphql';
import { sortContactTimeline } from 'src/components/domain/contact-timeline/helpers/sort-contact-timeline';
import { ContactDetailsHomeView } from 'src/components/domain/contacts/contact-modal/contact-modal.mobile-view';
import { ContactModalLoadingView } from 'src/components/domain/contacts/contact-modal/tabs/contact-modal-tabs.shared';
import { ContactModalTimelineItem } from 'src/components/domain/contacts/contact-modal/tabs/timeline/contact-modal-timeline-item';
import { ListLoadingItem } from 'src/components/general/feedback/list-loading-item';
import { FlexColumn } from 'src/components/layout/flex-column';
import {
  CORE_PAGE_INFO_FIELDS,
  CONTACT_INTERACTION_PARTICIPATING_ENTITY_CORE_FIELDS,
} from 'src/components/providers/apollo-provider-provider';
import { useBreakpoint } from 'src/hooks/use-breakpoint';
import { useCurrentInstitution } from 'src/hooks/use-current-institution';
import { useFormatDate } from 'src/hooks/use-format-date';
import { useHandleGraphQLError } from 'src/hooks/use-handle-graphql-error';
import { useInfiniteListQuery } from 'src/hooks/use-infinite-list-query';

export const GET_CONTACT_TIMELINE_QUERY = gql`
  ${CORE_PAGE_INFO_FIELDS}
  ${CONTACT_INTERACTION_PARTICIPATING_ENTITY_CORE_FIELDS}
  # noinspection GraphQLUnresolvedReference,GraphQLSchemaValidation
  query GetContactTimeline(
    $institutionId: Int!
    $contactId: Int!
    $first: Int
    $after: String
    $before: String
    $last: Int
    $where: ContactTimelineWhereInput
  ) {
    contactTimeline(
      institutionId: $institutionId
      contactId: $contactId
      first: $first
      after: $after
      before: $before
      last: $last
      where: $where
    ) @connection(key: "infinite-scroll") {
      pageInfo {
        ...PageInfoFragment
      }
      edges {
        cursor
        node {
          ... on ContactTimeline {
            id
            occurredAt
          }
          ... on ContactTimelineContactCreated {
            id
            occurredAt
          }
          ... on ContactTimelineWithTask {
            task {
              id
              title
            }
          }
          ... on ContactTimelineWithSchedule {
            schedule {
              id
              title
            }
          }
          ... on ContactTimelineWithOriginator {
            originator {
              id
              fullName
            }
          }
          ... on ContactTimelineContactStatusUpdated {
            oldStatus {
              id
              name
            }
            newStatus {
              id
              name
            }
          }
          ... on ContactTimelineTaskStatusUpdated {
            oldTaskStatus
            newTaskStatus
          }
          ... on ContactTimelineTaskDeleted {
            taskTitle
          }
          ... on ContactTimelineScheduleStatusUpdated {
            newScheduleStatus
            oldScheduleStatus
          }
          ... on ContactTimelineScheduleDeleted {
            appointmentTitle
          }
          ... on ContactTimelineNoteCreated {
            note {
              id
              note
            }
          }
          ... on ContactTimelineContactInteraction {
            contactInteraction {
              ... on ContactInteraction {
                id
                occurredAt
                isCreationAutomatic
                participatingEntity {
                  ...ContactInteractionParticipatingEntityFragment
                }
              }
              ... on ContactInteractionWithDirection {
                direction
              }
              ... on ContactInteractionPhoneCall {
                dialStatus
                callDetail {
                  inboundLastStep
                }
                recording {
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`;

const ContactDetailWrapper = styled(FlexColumn)<{ $fullHeight?: boolean }>`
  background-color: ${({ theme }) => theme.colors.porcelain};
  padding-bottom: 10px;
  ${({ $fullHeight }) => $fullHeight && 'height: 100%;'};
`;

const GroupHeader = styled.span`
  color: ${({ theme }) => theme.colors.flint};
  font-family: ${({ theme }) => theme.font.medium};
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  line-height: 14px;
  margin-left: 20px;
  text-align: left;

  ${up('md')} {
    color: ${({ theme }) => theme.colors.carbon};
    font-size: 14px;
    line-height: 17px;
  }
`;

const TimelineHeader = styled.div`
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.porcelain};
  color: ${({ theme }) => theme.colors.smalt};
  display: flex;
  font-family: ${({ theme }) => theme.font.medium};
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  height: 38px;
  letter-spacing: -0.28px;
  line-height: 17px;
  padding-left: 20px;
  text-align: left;
`;

const PAGE_SIZE = 50;

type UseRefreshContactTimelineTabProps = {
  onUpdate: (contactTimelineId: number) => Promise<void>;
  onCreate: () => Promise<void>;
  contactTimelines: ContactTimelineUnion[];
};

const actionPattern = P.union('update' as const, 'create' as const);

const basePattern = {
  id: P.number,
  action: actionPattern,
};

const taskEventPattern = {
  entity: {
    ...basePattern,
    type: 'task',
  },
};

const appointmentEventPattern = {
  entity: {
    ...basePattern,
    type: 'schedule',
  },
};

const contactInteractionEventPattern = {
  entity: {
    ...basePattern,
    type: 'activity',
  },
};

const contactNoteEventPattern = {
  entity: {
    ...basePattern,
    type: 'note',
  },
};

const useRefreshContactTimelineTab = (params: UseRefreshContactTimelineTabProps) => {
  const { onUpdate, contactTimelines, onCreate } = params;

  useEffect(() => {
    const handleRefresh = async (e, body) => {
      await match(body)
        .with(taskEventPattern, async (event) => {
          const { id, action } = event.entity;

          await match(action)
            .with('update', async () => {
              const contactTimeline = contactTimelines.find((node) =>
                match(node).with(
                  {
                    __typename: P.union('ContactTimelineTaskCreated'),
                  },
                  (ct) => ct.task.id === id,
                ),
              );

              if (contactTimeline) {
                await onUpdate(contactTimeline.id);
              }
            })
            .with('create', async () => await onCreate())
            .exhaustive();
        })
        .with(appointmentEventPattern, async (event) => {
          const { id, action } = event.entity;

          await match(action)
            .with('update', async () => {
              const contactTimeline = contactTimelines.find((node) =>
                match(node).with(
                  {
                    __typename: P.union(
                      'ContactTimelineScheduleCreated',
                      'ContactTimelineScheduleStatusUpdated',
                    ),
                  },
                  (ct) => ct.schedule.id === id,
                ),
              );

              if (contactTimeline) {
                await onUpdate(contactTimeline.id);
              }
            })
            .with('create', async () => await onCreate())
            .exhaustive();
        })
        .with(contactInteractionEventPattern, async (event) => {
          const { id, action } = event.entity;

          await match(action)
            .with('update', async () => {
              const contactTimeline = contactTimelines.find((node) =>
                match(node).with(
                  {
                    __typename: 'ContactTimelineContactInteraction',
                  },
                  (ct) =>
                    ct.contactInteraction && parseInt(ct.contactInteraction.id) === id,
                ),
              );

              if (contactTimeline) {
                await onUpdate(contactTimeline.id);
              }
            })
            .with('create', async () => await onCreate())
            .exhaustive();
        })
        .with(contactNoteEventPattern, async (event) => {
          const { id, action } = event.entity;

          await match(action)
            .with('update', async () => {
              const contactTimeline = contactTimelines.find((node) =>
                match(node).with(
                  {
                    __typename: 'ContactTimelineNoteCreated',
                  },
                  (ct) => ct.note.id === id,
                ),
              );

              if (contactTimeline) {
                await onUpdate(contactTimeline.id);
              }
            })
            .with('create', async () => await onCreate())
            .exhaustive();
        })
        .otherwise(() => {
          // no need to handle other events
        });
    };

    window.jQuery(window).on('refreshData', handleRefresh);

    return () => window.jQuery(window).off('refreshData', handleRefresh);
  });
};

type ContactModalTimelineTabProps = {
  contact: Contact;
  virtuosoRef?: RefObject<VirtuosoHandle>;
};

export const ContactModalTimelineTab = memo<ContactModalTimelineTabProps>(
  ({ contact, virtuosoRef }) => {
    const handleGraphQLError = useHandleGraphQLError();
    const breakpointUpMd = useBreakpoint(up('md'));
    const { t } = useTranslation(['contactTimeline']);
    const { formatDate } = useFormatDate();
    const { id: companyId } = useCurrentInstitution();
    const {
      loading,
      nodes,
      handleLoadBottom,
      loadingMoreBottom,
      handleLoadTop,
      fetchMore,
    } = useInfiniteListQuery<
      Pick<Query, 'contactTimeline'>,
      Partial<QueryContactTimelineArgs>,
      ContactTimelineUnion
    >({
      queryOptions: {
        getNodeIdCallback: (notification) => notification?.id,
        query: GET_CONTACT_TIMELINE_QUERY,
        variables: {
          first: PAGE_SIZE,
          institutionId: companyId,
          contactId: contact.id,
        },
        getEdgesCallback: (data) => data?.contactTimeline.edges ?? [],
        getPageInfoCallback: (data) => data?.contactTimeline.pageInfo,
        onError: handleGraphQLError,
        queryPageSize: PAGE_SIZE,
        sortNodesCallback: sortContactTimeline,
      },
      pollingOptions: {
        interval: 30_000,
      },
    });

    const refreshParams = useMemo(
      () => ({
        onUpdate: async (contactTimelineId) => {
          await fetchMore({
            variables: {
              where: {
                id: { _eq: contactTimelineId },
              },
            },
          });
        },
        onCreate: async () => {
          return new Promise<void>((resolve) => {
            setTimeout(() => {
              void handleLoadTop({ forceLoadMore: true }).then(resolve);
            }, 1000);
          });
        },
        contactTimelines: nodes,
      }),
      [fetchMore, handleLoadTop, nodes],
    );

    useRefreshContactTimelineTab(refreshParams);

    if (loading) {
      return <ContactModalLoadingView data-lgg-id="contact-modal-timeline-tab-loading" />;
    }

    const contactDetails = !breakpointUpMd ? (
      <ContactDetailWrapper $fullHeight={true}>
        <ContactDetailsHomeView contact={contact} />
      </ContactDetailWrapper>
    ) : null;

    if (!nodes.length && !breakpointUpMd) {
      return contactDetails;
    }

    const groups = groupBy(nodes, (contactTimeline) => {
      return formatDate(contactTimeline.occurredAt, 'MMMM YYYY');
    });

    const groupCounts = Object.keys(groups).map((key) => groups[key].length);
    const groupIndexes = Object.keys(groups);

    return (
      <GroupedVirtuoso
        ref={virtuosoRef}
        data-lgg-id="contact-modal-timeline-tab-list"
        groupCounts={groupCounts}
        endReached={() => handleLoadBottom()}
        components={{
          Header: () => {
            if (breakpointUpMd) {
              return null;
            }

            return (
              <>
                {contactDetails}
                <TimelineHeader>{t('contactTimeline:title')}</TimelineHeader>
              </>
            );
          },
          TopItemList: (props) => {
            return <div {...props} style={{ ...props.style, position: 'static' }} />;
          },
          Footer: () => (
            <ListLoadingItem
              data-lgg-id="contact-modal-timeline-tab-loading-more"
              visible={loadingMoreBottom}
            />
          ),
        }}
        groupContent={(index) => (
          <div style={{ paddingTop: index === 0 ? '15px' : '20px' }}>
            <GroupHeader>{groupIndexes[index]}</GroupHeader>
          </div>
        )}
        itemContent={(index) => {
          const contactTimeline = nodes[index];

          return (
            <ContactModalTimelineItem
              contactTimeline={contactTimeline}
              contact={contact}
            />
          );
        }}
      />
    );
  },
);
