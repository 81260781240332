import React, { memo, useMemo } from 'react';
import { Trans } from 'react-i18next';
import styled from 'styled-components';
import { match, P } from 'ts-pattern';
import {
  ContactInteractionParticipatingEntityEdge,
  ContactInteractionPhoneCall,
} from '@lgg/isomorphic/types/__generated__/graphql';
import {
  inboundPhoneCallInteractionPatterns,
  matchContactInteractionPhoneCallExhaustive,
} from '@lgg/isomorphic/utils/match-contact-interaction';
import { InProgressCallIndicator } from 'src/components/domain/contact-interaction/in-progress-call-indicator';
import { LggAudioPlayer } from 'src/components/general/display/lgg-audio-player';
import { PhoneCallAnalysis } from 'src/components/pages/conversations/components/contact-interactions/items/phone-call-analysis/phone-call-analysis';
import {
  BubbleDivider,
  BubbleMessageHeader,
  InteractionTextContent,
  MessageBubbleWithDirection,
} from 'src/components/pages/conversations/components/contact-interactions/items/shared';

const Description = styled(InteractionTextContent)`
  white-space: pre-wrap;
`;

const AutomaticBubbleDivider = styled(BubbleDivider)`
  margin-bottom: 10px;
  margin-top: 14px;
`;

const VoicemailRecordingContainer = styled.div`
  margin: 10px 0;
`;

const RedText = styled.span`
  color: ${({ theme }) => theme.colors.secondaryCoral};
`;

type PhoneCallItemProps = {
  contactInteraction: ContactInteractionPhoneCall;
  conversationId: string;
};

export const PhoneCallItem = memo<PhoneCallItemProps>(
  ({ contactInteraction, conversationId }) => {
    const {
      direction,
      occurredAt,
      isCreationAutomatic,
      description,
      id,
      campaign,
      source,
      department,
      callDetail,
      contactAddress,
      resourceAddress,
    } = contactInteraction;

    const title = useMemo(() => {
      const transComponents = { strong: <strong />, alert: <RedText /> };
      const getInboundAnsweredByInteractionTitle = ({
        participatingEntity,
      }:
        | ContactInteractionPhoneCall
        | (ContactInteractionPhoneCall & {
            participatingEntity: ContactInteractionParticipatingEntityEdge;
          })) => {
        if (participatingEntity) {
          return (
            <Trans
              i18nKey="conversations:contactInteractionBubble.phoneCall.inboundAnsweredWithParticipatingEntity"
              components={transComponents}
              values={{ participatingEntityLabel: participatingEntity.label }}
            />
          );
        }

        return (
          <Trans
            i18nKey="conversations:contactInteractionBubble.phoneCall.inboundAnsweredWithoutParticipatingEntity"
            components={transComponents}
          />
        );
      };

      return matchContactInteractionPhoneCallExhaustive(contactInteraction, {
        defaultInbound: () => (
          <Trans
            i18nKey="conversations:contactInteractionBubble.phoneCall.defaultInbound"
            components={transComponents}
          />
        ),
        defaultOutbound: ({ participatingEntity }) => (
          <Trans
            i18nKey="conversations:contactInteractionBubble.phoneCall.defaultOutbound"
            values={{ originator: participatingEntity?.label }}
            components={transComponents}
          />
        ),
        inboundManualWithParticipatingEntity: ({ participatingEntity }) => (
          <Trans
            i18nKey="conversations:contactInteractionBubble.phoneCall.inboundManualWithParticipatingEntity"
            components={transComponents}
            values={{ participatingEntityLabel: participatingEntity.label }}
          />
        ),
        inboundManualWithoutParticipatingEntity: () => (
          <Trans
            i18nKey="conversations:contactInteractionBubble.phoneCall.inboundManualWithoutParticipatingEntity"
            components={transComponents}
          />
        ),
        inboundAnsweredWithParticipatingEntity: ({ participatingEntity }) => (
          <Trans
            i18nKey="conversations:contactInteractionBubble.phoneCall.inboundAnsweredWithParticipatingEntity"
            components={transComponents}
            values={{ participatingEntityLabel: participatingEntity.label }}
          />
        ),
        inboundAnsweredWithoutParticipatingEntity: getInboundAnsweredByInteractionTitle,
        inboundUnansweredWithDialStepMenu: () => (
          <Trans
            i18nKey="conversations:contactInteractionBubble.phoneCall.inboundUnansweredWithDialStepMenu"
            components={transComponents}
          />
        ),
        inboundUnansweredWithDialStepMessage: () => (
          <Trans
            i18nKey="conversations:contactInteractionBubble.phoneCall.inboundUnansweredWithDialStepMessage"
            components={transComponents}
          />
        ),
        inboundUnansweredWithDialStepDial: () => (
          <Trans
            i18nKey="conversations:contactInteractionBubble.phoneCall.inboundUnansweredWithDialStepDial"
            components={transComponents}
          />
        ),
        inboundUnansweredWithDialStepVoicemailWithRecording: () => (
          <Trans
            i18nKey="conversations:contactInteractionBubble.phoneCall.inboundUnansweredWithDialStepVoicemailWithRecording"
            components={transComponents}
          />
        ),
        inboundUnansweredWithDialStepVoicemailWithoutRecording: () => (
          <Trans
            i18nKey="conversations:contactInteractionBubble.phoneCall.inboundUnansweredWithDialStepVoicemailWithoutRecording"
            components={transComponents}
          />
        ),
        inboundUnansweredWithoutDialStep: () => (
          <Trans
            i18nKey="conversations:contactInteractionBubble.phoneCall.inboundUnansweredWithoutDialStep"
            components={transComponents}
          />
        ),
        inboundOtherStatusDialStepMenu: () => (
          <Trans
            i18nKey="conversations:contactInteractionBubble.phoneCall.inboundOtherStatusDialStepMenu"
            components={transComponents}
          />
        ),
        inboundOtherStatusDialStepMessage: () => (
          <Trans
            i18nKey="conversations:contactInteractionBubble.phoneCall.inboundOtherStatusDialStepMessage"
            components={transComponents}
          />
        ),
        inboundOtherStatusDialStepDial: () => (
          <Trans
            i18nKey="conversations:contactInteractionBubble.phoneCall.inboundOtherStatusDialStepDial"
            components={transComponents}
          />
        ),
        inboundOtherStatusDialStepVoicemailWithRecording: () => (
          <Trans
            i18nKey="conversations:contactInteractionBubble.phoneCall.inboundOtherStatusDialStepVoicemailWithRecording"
            components={transComponents}
          />
        ),
        inboundOtherStatusDialStepVoicemailWithoutRecording: () => (
          <Trans
            i18nKey="conversations:contactInteractionBubble.phoneCall.inboundOtherStatusDialStepVoicemailWithoutRecording"
            components={transComponents}
          />
        ),
        inboundOtherStatusNoStep: () => (
          <Trans
            i18nKey="conversations:contactInteractionBubble.phoneCall.inboundOtherStatusNoStep"
            components={transComponents}
          />
        ),
        outboundManual: () => (
          <Trans
            i18nKey="conversations:contactInteractionBubble.phoneCall.outboundManual"
            components={transComponents}
          />
        ),
        outboundAnswered: () => (
          <Trans
            i18nKey="conversations:contactInteractionBubble.phoneCall.outboundAnswered"
            components={transComponents}
          />
        ),
        outboundWithCancelReason: () => (
          <Trans
            i18nKey="conversations:contactInteractionBubble.phoneCall.outboundWithCancelReason"
            components={transComponents}
          />
        ),
        outboundUnansweredWithBusyReason: () => (
          <Trans
            i18nKey="conversations:contactInteractionBubble.phoneCall.outboundUnansweredWithBusyReason"
            components={transComponents}
          />
        ),
        outboundUnansweredWithFailedReason: () => (
          <Trans
            i18nKey="conversations:contactInteractionBubble.phoneCall.outboundUnansweredWithFailedReason"
            components={transComponents}
          />
        ),
        outboundUnansweredWithOtherReason: () => (
          <Trans
            i18nKey="conversations:contactInteractionBubble.phoneCall.outboundUnansweredWithOtherReason"
            components={transComponents}
          />
        ),
        outboundOtherStatus: () => (
          <Trans
            i18nKey="conversations:contactInteractionBubble.phoneCall.outboundOtherStatus"
            components={transComponents}
          />
        ),
        inboundAnsweredWithParticipatingEntityInProgress: (interaction) => (
          <>
            {getInboundAnsweredByInteractionTitle(interaction)}
            <InProgressCallIndicator />
          </>
        ),
        inboundAnsweredWithoutParticipatingEntityInProgress: (interaction) => (
          <>
            {getInboundAnsweredByInteractionTitle(interaction)}
            <InProgressCallIndicator />
          </>
        ),
        inboundInProgress: () => (
          <>
            <Trans
              i18nKey="conversations:contactInteractionBubble.phoneCall.defaultInbound"
              components={transComponents}
            />
            <InProgressCallIndicator />
          </>
        ),
        outboundInProgress: () => (
          <>
            <Trans
              i18nKey="conversations:contactInteractionBubble.phoneCall.defaultOutbound"
              components={transComponents}
            />
            <InProgressCallIndicator />
          </>
        ),
      });
    }, [contactInteraction]);

    // Item copies and icons: https://app.zeplin.io/project/5eceb1da56628d4977620b86/screen/6541558d652c6e232824d366
    const icon = useMemo(() => {
      return match(contactInteraction)
        .with(
          inboundPhoneCallInteractionPatterns.inboundUnansweredWithDialStepVoicemailWithRecording,
          inboundPhoneCallInteractionPatterns.inboundOtherStatusDialStepVoicemailWithoutRecording,
          inboundPhoneCallInteractionPatterns.inboundOtherStatusDialStepVoicemailWithRecording,
          inboundPhoneCallInteractionPatterns.inboundOtherStatusNoStep,
          () => 'voicemail',
        )
        .with({ isCreationAutomatic: false }, () => 'iconInteraction')
        .with({ direction: 'OUTBOUND', dialStatus: 'ANSWERED' }, () => 'callOutgoing')
        .with({ dialStatus: 'ANSWERED' }, () => 'callIncoming')
        .with({ dialStatus: 'UNANSWERED' }, () => 'callCancelled')
        .otherwise(() => 'callHangup');
    }, [contactInteraction]);

    return (
      <MessageBubbleWithDirection
        testId="contact-interaction-phone-call"
        direction={direction}
        isCreationAutomatic={isCreationAutomatic}
        createdAt={occurredAt}
        isCall
        details={{
          contactAddress,
          resourceAddress,
          interactionId: id,
          source,
          conversationId,
          campaign,
          department,
          message: !isCreationAutomatic ? description : null,
        }}
      >
        <>
          <BubbleMessageHeader
            icon={icon}
            title={title}
            contactInteraction={contactInteraction}
          />
          {match(contactInteraction)
            .with({ isCreationAutomatic: false }, () => {
              return (
                <>
                  <BubbleDivider direction={direction} />
                  <Description direction={direction}>{description}</Description>
                </>
              );
            })
            .with({ recording: P.not(P.nullish) }, ({ recording, direction }) => (
              <>
                <VoicemailRecordingContainer>
                  <LggAudioPlayer
                    allowDownload
                    url={recording.url}
                    testId="contact-interaction-voicemail"
                  />
                </VoicemailRecordingContainer>
                {callDetail?.transcription && (
                  <div>
                    <PhoneCallAnalysis
                      details={callDetail}
                      recordingSrc={recording.url}
                      direction={direction}
                    />
                    <AutomaticBubbleDivider direction={direction} />
                  </div>
                )}
              </>
            ))
            .otherwise(() => (
              <AutomaticBubbleDivider direction={direction} />
            ))}
        </>
      </MessageBubbleWithDirection>
    );
  },
);
